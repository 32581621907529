<template>
  <div>
    <div :class="`mb-10 ${$vuetify.breakpoint.xs?'':'indent'}`">
      <h1>Download our app for iOS and Android!</h1>
      <p>
        Download our app to track your activities and steps. Get real-time updates on challenge progress and view the leaderboards and results.
      </p>
      <img class="elevation-12 " style="max-width:100%;" src="https://sodisp.imgix.net/web/app/cofi-app-devices.png?w=1080" alt="Get our app." /> 

      <v-row>
        <v-col cols="12" class="text-center">
          <h1 class="subtitle">Download now!</h1>
        </v-col>
        <v-col cols="6" style="display:flex;flex-direction:column;align-items:flex-end;">
          <a class="mr-2" href='https://apps.apple.com/app/connected-corporate-fitness/id1594403776' target="_blank"><img style="max-width:100%;" alt='Download on the App Store' src='https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred.png'/></a>
        </v-col>
        <v-col cols="6">
          <a href='https://play.google.com/store/apps/details?id=app.corporatefitness' target="_blank"><img style="max-width:215px; margin: -15px;" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
        </v-col>
      </v-row>

    </div>

    <div id="" class="mt-0 white--text">
      <div class="container pb-0">
        <v-row>
          <v-col class="pb-4">
            <v-img src="https://sodisp.imgix.net/web/app/cofi-app-iphones.png?w=1080" />
          </v-col>
        </v-row>
      </div>
    </div>

    <div>
      <h2 class="subheader mt-8">Track your steps and activities</h2>
      <p>
        Use the integrated step and activity tracking in our app to track your daily steps, runs, walks and rides!
      </p>
      <v-row>
        <v-col cols="6" md="4">
          <v-sheet :elevation="1" class="text-center align-center pa-4" >
            <v-icon size="50" color="grey">fa-shoe-prints</v-icon>
            <h3 class="my-4">Daily Steps</h3>
            <p>Make every step count using our integrated step tracker.</p>
          </v-sheet>
        </v-col>
        <v-col cols="6" md="4">
          <v-sheet :elevation="1" class="text-center align-center pa-4" >
            <v-icon size="50" color="grey">fa-watch-fitness</v-icon>
            <h3 class="my-4">Apple Watch</h3>
            <p>Auto-sync steps and activities from your Apple Watch.</p>
          </v-sheet>
        </v-col>
        <v-col cols="6" md="4">
          <v-sheet :elevation="1" class="text-center align-center pa-4">
            <v-icon size="50" color="grey">fa-route</v-icon>
            <h3 class="my-4">Track your Activities</h3>
            <p>Use our integrated GPS-tracker to track your runs, walks, and rides.</p>
          </v-sheet>
        </v-col>
      </v-row>
    </div>

    <div>
      <h2 class="subheader mt-8">Integration with all major fitness trackers</h2>
      <p>
        We offer native integration with all major fitness wearables for automatic upload of activities. This greatly improves the experience of your participants! <br/>
        The app contains basic fitness tracking features which makes it a great choice for participants who are not yet using another tracker!
      </p>
      <SectionIntegrations />
    </div>

    <div class="green white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="subtitle">Get started today!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

  </div>
</template>


<script>
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import siteData from '@/data/site.json'

export default {
  name: "Contact",
  components: {
    SectionIntegrations,
    SectionCustomers,
  },
  props: {
  },
  data: function() {
    return {
      siteData: siteData,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style>